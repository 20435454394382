import { BACKEND_BASE_URL, BACKEND_URL_PREFIX } from '../../Constants';

const getFullUrl = (url: string, params?: Record<string, string>): string => {
  let fullUrl = `${BACKEND_BASE_URL}/${BACKEND_URL_PREFIX}/${url}`;
  if (params != null) {
    fullUrl += `?${new URLSearchParams(params).toString()}`;
  }
  return fullUrl;
};

const get = async (url: string): Promise<Response> => {
  return await fetch(getFullUrl(url), {
    method: 'GET',
  });
};

const post = async (url: string, params: Record<string, string>, body: Record<string, object>): Promise<Response> => {
  return await fetch(getFullUrl(url, params), {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

const HttpUtils = {
  get,
  post,
};

export default HttpUtils;
