import { useCallback, useEffect, useState } from 'react';

export const useDelayedOpening = (conditions: boolean[], waitTime = 500): boolean => {
  const [isWaitTimeOver, setIsWaitTimeOver] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsWaitTimeOver(true);
    }, waitTime);
  }, [waitTime]);

  return isWaitTimeOver && conditions.every((condition) => condition === true);
};

export const usePreloadImages = (imageUrls: string[], isAnonymous = true): boolean => {
  const [loading, setLoading] = useState(true);

  const preloadImages = useCallback(async () => {
    if (imageUrls.length === 0) {
      setLoading(false);
      return;
    }
    if (imageUrls.every((url) => window.preloadImagesData?.[url] != null)) {
      setLoading(false);
      return;
    }
    setLoading(true);
    await Promise.all(
      imageUrls.map(async (url: string) => {
        await preloadImageWithCors(url, isAnonymous);
      })
    );
    setLoading(false);
  }, [imageUrls, isAnonymous]);

  useEffect(() => {
    preloadImages();
  }, [preloadImages]);

  return loading;
};

export const preloadImageWithCors = async (url: string, isAnonymous: boolean): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve) => {
    if (window.preloadImagesData?.[url] != null) {
      resolve(window.preloadImagesData[url]);
    }
    const img = new Image();
    img.onload = () => {
      if (window.preloadImagesData == null) {
        window.preloadImagesData = {};
      }
      window.preloadImagesData[url] = img;
      resolve(img);
    };
    img.crossOrigin = isAnonymous ? 'anonymous' : null;
    img.src = url;
  });
};
