import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Radio,
  RadioGroup,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { saveAs } from 'file-saver';
import {
  EditMode,
  useWorkshopContext,
  Workshop as WorkshopType,
  WorkshopContextProvider,
} from '../../../hooks/build_a_bobo';
import { Colors } from '../../../Constants';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { BuilderLayer, BuilderLayerAsset } from '../../../hooks/build_a_bobo/types';
import { ChromePicker, ColorResult } from 'react-color';
import Button from '../../Common/Button/Button';
import Card from '../../Common/Container/Card';
import ContainerWithPlaceholder from '../../Common/Container/ContainerWithPlaceholder';
import Header from '../../Common/Header/Header';
import SpinnerModal from '../../Common/Loading/SpinnerModal';
import Spacer from '../../Common/Spacer/Spacer';
import styles from './Workshop.module.css';

interface LayerDropdown {
  buttonLabel: string;
  layer: BuilderLayer;
}

const LayerDropdown = ({ buttonLabel, layer }: LayerDropdown): ReactElement => {
  const { modifyBlueprint } = useWorkshopContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = (): void => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleAssetClick = async (asset: BuilderLayerAsset, layerId: string): Promise<void> => {
    modifyBlueprint(asset, layerId);
  };

  return (
    <>
      <Button
        key={layer.key}
        variant="square"
        buttonColor="#e9b3dc"
        textAlign="start"
        width="70%"
        buttonSize="md"
        boxShadow="rgb(0 0 0 / 30%) 0px 2px 4px"
        onClick={handleButtonClick}>
        {buttonLabel}
        {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </Button>
      {isOpen && (
        <ScaleFade in={isOpen}>
          <Box px={3} py={2}>
            <Grid gap={5} templateColumns="repeat(4, minmax(0, 1fr))">
              {layer.assets.map((asset) => (
                <Card
                  key={asset.displayName}
                  p={2}
                  textAlign="center"
                  bgColor="white"
                  cursor="pointer"
                  onClick={() => handleAssetClick(asset, layer.key)}>
                  <Image src={asset.url} borderRadius={9} bgColor={Colors.Button.SECONDARY} width="100%" />
                  <Text textTransform="capitalize" fontSize="xs">
                    {asset.displayName.split('_').join(' ')}
                  </Text>
                </Card>
              ))}
            </Grid>
          </Box>
        </ScaleFade>
      )}
    </>
  );
};

const UserInterface = (): ReactElement => {
  const { layers } = useWorkshopContext();

  return (
    <Flex flex={1} flexDirection="column" gap={4}>
      <Heading size="lg">Layers</Heading>
      <VStack flex={1} width="100%" spacing={3} alignItems="start" pt={5} overflow="auto">
        {layers?.map((layer, index) => (
          <LayerDropdown key={index} buttonLabel={`${index + 1}. ${layer.displayName}`} layer={layer} />
        ))}
      </VStack>
    </Flex>
  );
};

const Preview = (): ReactElement => {
  const {
    canvasRef,
    editMode,
    randomizeBlueprint,
    clearStickerQueue,
    onStickerCanvasClick,
    onPainterMouseMove,
    onPainterMouseDown,
    onPainterMouseUp,
    onPainterMouseLeave,
  } = useWorkshopContext();

  const randomizeHandler = (): void => {
    clearStickerQueue();
    randomizeBlueprint();
  };

  const downloadHandler = (): void => {
    const dataUrl = canvasRef.current?.toDataURL();
    if (dataUrl != null) {
      saveAs(dataUrl, 'download.jpg');
    }
  };

  return (
    <Flex width="25vw" flexDirection="column" gap={8}>
      <Heading size="lg">Preview</Heading>
      <Card bgColor="white" overflow="hidden" boxShadow="rgb(0 0 0 / 30%) 0px 0px 2px, rgb(0 0 0 / 30%) 0px 4px 8px">
        <canvas
          ref={canvasRef}
          className={editMode === EditMode.PEN ? styles.penModeCanvas : styles.stickerModeCanvas}
          width="1080px"
          height="1080px"
          onClick={onStickerCanvasClick}
          onMouseMove={onPainterMouseMove}
          onMouseDown={onPainterMouseDown}
          onMouseUp={onPainterMouseUp}
          onMouseLeave={onPainterMouseLeave}
        />
      </Card>
      <Flex gap={5} justifyContent="center">
        <Button variant="rounded" boxShadow="rgb(0 0 0 / 30%) 0px 2px 4px" buttonSize="lg" onClick={randomizeHandler}>
          Randomize
        </Button>
        <Button variant="rounded" boxShadow="rgb(0 0 0 / 30%) 0px 2px 4px" buttonSize="lg" onClick={downloadHandler}>
          Download
        </Button>
      </Flex>
    </Flex>
  );
};

const StickerPicker = () => {
  const { stickerOptions, selectSticker, setEditMode } = useWorkshopContext();

  const handleClick = (sticker: string) => {
    selectSticker(sticker);
    setEditMode(EditMode.STICKER);
  };

  return (
    <HStack width="100%" spacing={3} overflowX="auto" px={2} py={3}>
      {stickerOptions?.map((sticker) => (
        <Card
          key={sticker}
          p={1}
          minW="20%"
          maxW="20%"
          cursor="pointer"
          onClick={() => handleClick(sticker)}
          draggable={true}>
          <Image src={sticker} bgColor={Colors.Button.SECONDARY} borderRadius={9} />
        </Card>
      ))}
    </HStack>
  );
};

const PenSizePicker = (): ReactElement => {
  const { penSize, setPenSize, penColor, setEditMode } = useWorkshopContext();

  const onChangeHandler = (value: string) => {
    setPenSize(Number(value));
    setEditMode(EditMode.PEN);
  };

  const penSizes = [5, 10, 15];
  return (
    <RadioGroup value={penSize.toString()} onChange={onChangeHandler}>
      <Flex justifyContent="center" alignItems="center" gap={5}>
        {penSizes.map((size) => (
          <VStack justifyContent="space-between" height="80px" width="50px">
            <Center flex={1}>
              <Box height={`${size}px`} width={`${size}px`} bgColor={penColor} borderRadius="50%" />
            </Center>
            <Radio size="sm" value={size.toString()} />
          </VStack>
        ))}
      </Flex>
    </RadioGroup>
  );
};

const EditingTools = (): ReactElement => {
  const { penColor, setPenColor, setEditMode } = useWorkshopContext();

  const onChangeHandler = (color: ColorResult) => {
    setPenColor(color.hex);
    setEditMode(EditMode.PEN);
  };

  return (
    <Flex flex={1} flexDirection="column" gap={8}>
      <Heading size="lg">Tools</Heading>
      <VStack spacing={5}>
        <ChromePicker className={styles.chromePicker} color={penColor} onChange={onChangeHandler} />
        <StickerPicker />
        <PenSizePicker />
      </VStack>
    </Flex>
  );
};

const WorkshopUnconnected = ({ workshop }: WorkshopProps) => {
  const { cachingImages, isFetchingImages } = useWorkshopContext();

  const showSpinner = cachingImages;

  return (
    <ContainerWithPlaceholder loading={isFetchingImages}>
      <Header subheader={`~ ${workshop.displayName} ~`} color={Colors.Text.NORMAL_SECONDARY}>
        Build A Bobo
      </Header>
      <Spacer size={6} />
      <Card width="100%" height="80vh" p={10}>
        <Flex justifyContent="center" gap={12} height="100%">
          <UserInterface />
          <Preview />
          <EditingTools />
        </Flex>
      </Card>
      <Spacer size={16} />
      <SpinnerModal isOpen={showSpinner} />
    </ContainerWithPlaceholder>
  );
};

interface WorkshopProps {
  workshop: WorkshopType;
}

const Workshop = ({ workshop }: WorkshopProps): ReactElement => {
  return (
    <WorkshopContextProvider workshopKey={workshop.key}>
      <WorkshopUnconnected workshop={workshop} />
    </WorkshopContextProvider>
  );
};

export default Workshop;
