import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface SpacerProps {
  size: number | string;
}

const Spacer = ({ size }: SpacerProps): ReactElement => {
  return <Box height={size} />;
};

export default Spacer;
