import { Image, Stack, useBreakpointValue } from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  CITY_IMAGE_URL,
  PINK_CLOUD_RIGHT_IMAGE_URL,
} from "../../../../Constants";
import { useFadeInComponent } from "../../../../hooks/animation";

const CityScape = (): ReactElement => {
  const fadeInProps = useFadeInComponent();

  const cityHeight = useBreakpointValue({
    base: "35vh",
    sm: "40vh",
    md: "44vh",
    lg: "46vh",
    xl: "49vh",
  });

  return (
    <Stack
      direction="row"
      position="absolute"
      left={0}
      top={-8}
      {...fadeInProps}
    >
      <Image height={cityHeight} src={CITY_IMAGE_URL} />
      <Image
        height={{
          base: "10vh",
          sm: "12vh",
          md: "14vh",
          lg: "15vh",
          xl: "17vh",
        }}
        src={PINK_CLOUD_RIGHT_IMAGE_URL}
        transform="translateY(8vh)"
      />
    </Stack>
  );
};

export default CityScape;
