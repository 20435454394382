import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReactElement, useCallback, useEffect } from 'react';
import { Connector } from 'wagmi';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useMessageToast } from '../../../hooks/toast';
import { closeConnectorModal } from '../../../redux/navigation/action';
import { ReactComponent as MetamaskLogo } from './metamask.svg';
import { ReactComponent as CoinbaseWalletLogo } from './coinbase_wallet.svg';
import { ReactComponent as WalletConnectLogo } from './walletconnect.svg';
import { useWeb3Connect } from '../../../hooks/web3';
import { BOBO_AVATAR_IMAGE_URL } from '../../../Constants';
import { ConnectionError } from '../../../web3/connectors';
import Button from '../Button/Button';
import Header from '../Header/Header';

interface WalletNotConnectedViewProps {
  connect: (
    args:
      | Partial<{
          chainId?: number;
          connector: Connector;
        }>
      | undefined
  ) => void;
  connectors: Connector[];
}

const WalletNotConnectedView = ({ connect, connectors }: WalletNotConnectedViewProps): ReactElement => {
  const handleConnectorClick = (connector: Connector) => {
    connect({ connector });
  };

  return (
    <VStack spacing={3} position="relative">
      <Image src={BOBO_AVATAR_IMAGE_URL} borderRadius="50%" width="50%" mb={7} />
      {connectors.map((connector) => {
        let IconComponent = null;
        if (connector.name === 'MetaMask') {
          IconComponent = MetamaskLogo;
        } else if (connector.name === 'Coinbase Wallet') {
          IconComponent = CoinbaseWalletLogo;
        } else if (connector.name === 'WalletConnect') {
          IconComponent = WalletConnectLogo;
        } else {
          IconComponent = null;
        }

        return (
          <Button
            key={connector.id}
            buttonSize="lg"
            width="400px"
            maxWidth="100%"
            textAlign="start"
            disabled={!connector.ready}
            onClick={() => handleConnectorClick(connector)}>
            {IconComponent != null ? (
              <IconComponent height="25px" width="25px" style={{ marginRight: '15px' }} />
            ) : undefined}
            {connector.name}
          </Button>
        );
      })}
    </VStack>
  );
};

interface WalletConnectionContentProps {
  isConnecting: boolean;
  pendingConnector: Connector | undefined;
  connect: (
    args:
      | Partial<{
          chainId?: number;
          connector: Connector;
        }>
      | undefined
  ) => void;
  connectors: Connector[];
  error: Error | null;
}

const WalletConnectionContent = ({
  isConnecting,
  pendingConnector,
  connect,
  connectors,
  error,
}: WalletConnectionContentProps): ReactElement => {
  const getErrorMessage = useCallback(() => {
    const cause = error?.cause as { code: number } | undefined;
    if (cause?.code === ConnectionError.RESOURCE_UNAVAILABLE) {
      return 'Please complete the current connection request';
    }
    return error?.message ?? null;
  }, [error]);
  useMessageToast({
    message: getErrorMessage(),
    title: 'Blockchain Connection Error',
  });

  let content = null;
  if (isConnecting && pendingConnector != null) {
    content = (
      <VStack spacing={5}>
        <Spinner size="xl" />
        <Text>Connecting to {pendingConnector.name}</Text>
      </VStack>
    );
  } else {
    content = <WalletNotConnectedView connect={connect} connectors={connectors} />;
  }

  return (
    <>
      <ModalHeader alignSelf="center">
        <Header>Connect Your Wallet</Header>
      </ModalHeader>
      <ModalCloseButton disabled={isConnecting} />
      <ModalBody>{content}</ModalBody>
      <ModalFooter />
    </>
  );
};

const WalletconnectionModal = (): ReactElement => {
  const web3Connect = useWeb3Connect();
  const { isConnectorModalOpen } = useAppSelector((state) => state.navigation);
  const dispatch = useAppDispatch();

  const { isConnecting, isConnected } = web3Connect;

  const onCloseHandler = useCallback(() => {
    if (isConnecting) {
      return;
    }
    dispatch(closeConnectorModal());
  }, [isConnecting, dispatch]);

  useEffect(() => {
    if (isConnected) {
      dispatch(closeConnectorModal());
    }
  }, [isConnected, dispatch]);

  return (
    <Modal
      isOpen={isConnectorModalOpen}
      onClose={onCloseHandler}
      closeOnOverlayClick={!isConnecting}
      scrollBehavior="inside"
      isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={10} maxWidth={{ base: '90vw', md: '30vw' }} py={3}>
        <WalletConnectionContent {...web3Connect} />
      </ModalContent>
    </Modal>
  );
};

export default WalletconnectionModal;
