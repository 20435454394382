import { useMemo } from "react";
import { useInView } from "react-intersection-observer";

export const useFadeInComponent = () => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const animationClass = useMemo(
    () => (inView ? "fade-in" : undefined),
    [inView]
  );

  return {
    ref,
    className: animationClass,
  };
};
