import { extendTheme } from "@chakra-ui/react"
import { Colors, Fonts } from "../Constants"

const theme = {
  colors: {
    text: "#442316"
  },
  styles: {
    global: {
      body: {
        bgColor: Colors.Background.PRIMARY,
        color: Colors.Text.NORMAL,
        fontFamily: Fonts.Normal
      }
    },
    breakpoints: {
      sm: "480px",
      md: "768px",
      lg: "1024px",
      xl: "1200px"
    }
  },
}

const customTheme = extendTheme(theme)

export default customTheme