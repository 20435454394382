import { Image, Text, VStack } from '@chakra-ui/react';
import { PropsWithChildren, ReactElement } from 'react';
import { BOBO_AVATAR_IMAGE_URL } from '../../../Constants';
import { useNftOwnership, useWeb3Connect } from '../../../hooks/web3';
import GlobalContainerWithNav from '../Container/GlobalContainerWithNav';
import Web3Connector from '../Nav/Web3Connector';
import Spacer from '../Spacer/Spacer';

const NotPermittedNotice = (): ReactElement => {
  return (
    <VStack width="300px" spacing={10}>
      <Image src={BOBO_AVATAR_IMAGE_URL} borderRadius="50%" bgColor="#C5E7F1" />
      <Text textAlign="center" fontSize="lg">
        Hmm...it seems like you don't own a Boba Inu NFT, so you won't be able to access our exclusive apps and features
      </Text>
    </VStack>
  );
};

const NotConnectedNotice = (): ReactElement => {
  return (
    <VStack width="300px" spacing={8}>
      <Image src={BOBO_AVATAR_IMAGE_URL} borderRadius="50%" bgColor="#C5E7F1" />
      <Text textAlign="center" fontSize="lg">
        Please connect your wallet to use our exclusive features
      </Text>
      <Web3Connector />
    </VStack>
  );
};

const TokenGatedRoute = ({ children }: PropsWithChildren): ReactElement | null => {
  const { isConnected, connectedWalletAddress } = useWeb3Connect();
  const { isOwner } = useNftOwnership(connectedWalletAddress);

  if (!isConnected) {
    return (
      <GlobalContainerWithNav>
        <NotConnectedNotice />
      </GlobalContainerWithNav>
    );
  }

  if (!isOwner) {
    return (
      <GlobalContainerWithNav>
        <Spacer size={6} />
        <NotPermittedNotice />
      </GlobalContainerWithNav>
    );
  }

  return <>{children}</>;
};

export default TokenGatedRoute;
