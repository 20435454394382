import { Button as ChakraUiButton } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/system';
import { ReactElement } from 'react';
import { Colors } from '../../../Constants';
import Color from 'color';
import './Button.css';

interface Props extends ButtonProps {
  buttonSize?: 'md' | 'lg';
  buttonColor?: `#${string}`;
  variant?: 'rounded' | 'square';
}

const DEFAULT_PADDING = '3px 22px';

const SIZE_PADDING_MAP: Record<Required<Props>['buttonSize'], string> = {
  md: DEFAULT_PADDING,
  lg: DEFAULT_PADDING,
};

const SIZE_HEIGHT_MAP: Record<Required<Props>['buttonSize'], string> = {
  md: '33px',
  lg: '53px',
};

const SIZE_LINE_HEIGHT_MAP: Record<Required<Props>['buttonSize'], string> = {
  md: '23px',
  lg: '41px',
};

const VARIANT_PROPS: Record<Required<Props>['variant'], { shadowProps: object; frontProps: object }> = {
  rounded: {
    shadowProps: {
      borderRadius: '50px',
    },
    frontProps: {
      borderRadius: '50px',
    },
  },
  square: {
    shadowProps: {
      borderRadius: '12px',
    },
    frontProps: {
      borderRadius: '12px',
    },
  },
};

const Button = forwardRef(
  (
    { variant = 'square', buttonSize = 'md', buttonColor = Colors.Button.PRIMARY, children, ...props }: Props,
    ref
  ): ReactElement => {
    const shadowColor = Color(buttonColor).darken(0.175).hex();
    const { shadowProps, frontProps } = VARIANT_PROPS[variant];

    return (
      <ChakraUiButton
        {...props}
        {...shadowProps}
        cursor="var(--cursor-pointer)"
        ref={ref}
        border="none"
        bgColor={shadowColor}
        height={SIZE_HEIGHT_MAP[buttonSize]}
        padding={0}
        _hover={{}}
        _active={{}}
        size={buttonSize}
        className="pushable">
        <span
          className="pushable-front"
          style={{
            ...frontProps,
            padding: SIZE_PADDING_MAP[buttonSize],
            lineHeight: SIZE_LINE_HEIGHT_MAP[buttonSize],
            backgroundColor: buttonColor,
            borderColor: buttonColor,
          }}>
          {children}
        </span>
      </ChakraUiButton>
    );
  }
);

export default Button;
