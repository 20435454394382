import { Card, CardBody, Grid, Image, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreloadImages } from '../../hooks/optimization';
import { useWeb3Connect } from '../../hooks/web3';
import ContainerWithPlaceholder from '../Common/Container/ContainerWithPlaceholder';
import GlobalContainerWithNav from '../Common/Container/GlobalContainerWithNav';
import Header from '../Common/Header/Header';
import Spacer from '../Common/Spacer/Spacer';

interface App {
  id: string;
  name: string;
  description: string;
  imgSrc: string;
  redirectUrl: string;
  external: boolean;
}

const APPS: App[] = [
  {
    id: 'build_a_bobo',
    name: 'Build a Bobo Workshop',
    description: 'Create, customize, and mint your own Bobo! You can even turn it into merchandise.',
    imgSrc: 'https://bobainu-apps.s3.us-west-2.amazonaws.com/app_thumbnails/build_a_bobo_thumbnail.jpg',
    redirectUrl: '/holder/apps/build_a_bobo',
    external: false,
  },
  {
    id: 'liisa',
    name: 'Liisa',
    description:
      'Make better and faster NFT investment choices by leveraging this AI powered tool to view and analyze data on NFT collections.',
    imgSrc: 'https://bobainu-apps.s3.us-west-2.amazonaws.com/app_thumbnails/liisa_thumbnail.jpg',
    redirectUrl: 'https://liisa.io/auth',
    external: true,
  },
];

const AppPage = (): ReactElement => {
  const { isConnecting } = useWeb3Connect();
  const isPreloadingImages = usePreloadImages(APPS.map((app) => app.imgSrc));
  const columns = useBreakpointValue({
    base: 2,
    sm: 3,
    md: 4,
    xl: 5,
  });
  const navigate = useNavigate();

  const loading = isConnecting || isPreloadingImages;

  const handleAppClick = (app: App): void => {
    if (app.external) {
      window.open(app.redirectUrl, '_blank', 'noreferrer');
    } else {
      navigate(app.redirectUrl);
    }
  };

  return (
    <ContainerWithPlaceholder loading={loading}>
      <GlobalContainerWithNav>
        <Header>Apps</Header>
        <Spacer size={6} />
        <Grid
          width={{ base: '90%', lg: '75%' }}
          columnGap="3vw"
          rowGap="5vh"
          templateColumns={`repeat(${columns}, 1fr)`}
          zIndex={1}>
          {APPS.map((app) => (
            <Card
              key={app.id}
              bgColor="white"
              color="inherit"
              cursor="pointer"
              transitionDuration="0.5s"
              onClick={() => handleAppClick(app)}
              _hover={{ transform: 'scale(1.025, 1.025)' }}>
              <CardBody>
                <VStack spacing={1} textAlign="center">
                  <Image src={app.imgSrc} mb={4} borderRadius={8} />
                  <Text fontWeight="bold">{app.name}</Text>
                  <Text fontSize="sm">{app.description}</Text>
                </VStack>
              </CardBody>
            </Card>
          ))}
        </Grid>
      </GlobalContainerWithNav>
    </ContainerWithPlaceholder>
  );
};

export default AppPage;
