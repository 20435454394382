import {
  createContext,
  DragEvent,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getLayersAndAssetsForWorkshop, getStickerStamperAssets, getWorkshops } from '../../api/build_a_bobo';
import { usePreloadImages } from '../optimization';
import { BuilderBlueprint, BuilderLayer, BuilderLayerAsset } from './types';

export interface Workshop {
  key: string;
  displayName: string;
}

export const useBuildABoboWorkshops = () => {
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const fetchWorkshopList = async (): Promise<void> => {
    const workshops = await getWorkshops();
    setWorkshops(
      workshops?.map((workshop) => ({
        key: workshop.key,
        displayName: workshop.display_name,
      })) ?? []
    );
  };

  useEffect(() => {
    fetchWorkshopList();
  }, []);

  return workshops;
};

export enum EditMode {
  PEN = 'pen',
  STICKER = 'sticker',
}

export type EditModeType = (typeof EditMode)[keyof typeof EditMode];

export const useWorkshop = (workshopName: string) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvas = canvasRef.current;

  const [editMode, setEditMode] = useState(EditMode.PEN);
  const { isFetchingLayers, layers } = useWorkshopBuilderLayers(workshopName);
  const {
    cachingImages: cachingStickerImages,
    isFetchingStickers,
    queuedStickers,
    ...stickerStamperFlow
  } = useStickerStamper(workshopName, canvas, editMode);
  const { builderBlueprint, modifyBlueprint, randomizeBlueprint } = useWorkshopBuilderBlueprint(layers);
  const painterFlow = usePainter(canvas, editMode);
  const cachingLayerImages = usePreloadImages(layers?.flatMap((layer) => layer.assets).map((asset) => asset.url) ?? []);

  const isFetchingImages = isFetchingLayers || isFetchingStickers;
  const cachingImages = cachingLayerImages || cachingStickerImages;

  const drawLayers = useCallback(async () => {
    if (isFetchingImages || cachingImages || canvas == null || builderBlueprint == null) {
      return;
    }
    const context = canvas.getContext('2d');
    if (context == null) {
      return;
    }
    for (const layer of builderBlueprint) {
      if (layer.asset == null) {
        continue;
      }
      const img = window.preloadImagesData?.[layer.asset.url];
      if (img != null) {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    }
  }, [isFetchingImages, cachingImages, canvas, builderBlueprint]);

  const drawStickers = useCallback(async () => {
    if (isFetchingImages || cachingImages || canvas == null) {
      return;
    }
    const context = canvas.getContext('2d');
    if (context == null) {
      return;
    }
    for (const sticker of queuedStickers) {
      const { image, position } = sticker;
      context.drawImage(image, position.x - 80, position.y - 80, canvas.width / 7, canvas.height / 7);
    }
  }, [cachingImages, canvas, isFetchingImages, queuedStickers]);

  useEffect(() => {
    drawLayers();
  }, [drawLayers]);

  useEffect(() => {
    drawStickers();
  }, [drawStickers]);

  return {
    isFetchingImages,
    cachingImages,
    layers,
    builderBlueprint,
    modifyBlueprint,
    randomizeBlueprint,
    canvasRef,
    editMode,
    setEditMode,
    ...stickerStamperFlow,
    ...painterFlow,
  };
};

export const useWorkshopBuilderLayers = (workshopName: string) => {
  const [layers, setLayers] = useState<BuilderLayer[] | null>(null);
  const [isFetchingLayers, setIsFetchingLayers] = useState(false);

  const addLayer = (newLayer: BuilderLayer) => {
    setLayers((layers) => {
      if (layers == null) {
        return layers;
      }
      layers.push(newLayer);
      return layers;
    });
  };

  const fetchLayersAndAssets = useCallback(async () => {
    setIsFetchingLayers(true);
    const fetchedLayers = await getLayersAndAssetsForWorkshop(workshopName);
    setLayers(
      fetchedLayers?.map((layer) => ({
        displayName: layer.display_name,
        key: layer.key,
        assets: layer.assets.map((asset) => ({
          displayName: asset.display_name,
          url: asset.url,
        })),
      })) ?? null
    );
    setIsFetchingLayers(false);
  }, [workshopName]);

  useEffect(() => {
    fetchLayersAndAssets();
  }, [fetchLayersAndAssets]);

  return {
    isFetchingLayers,
    layers,
    addLayer,
  };
};

export const useWorkshopBuilderBlueprint = (layers: BuilderLayer[] | null) => {
  const [builderBlueprint, setBuilderBlueprint] = useState<BuilderBlueprint | null>(null);

  const modifyBlueprint = (asset: BuilderLayerAsset, layerKey: string) => {
    setBuilderBlueprint((builderBlueprint) => {
      const layer = builderBlueprint?.find((layer) => layer.key === layerKey);
      if (layer != null) {
        layer.asset = asset;
      }
      return builderBlueprint != null ? [...builderBlueprint] : null;
    });
  };

  const randomizeBlueprint = useCallback(
    () =>
      setBuilderBlueprint(
        layers?.map((layer) => {
          const randomIndex = Math.floor(Math.random() * (layer.assets.length - 1));
          return { key: layer.key, asset: layer.assets[randomIndex] };
        }) ?? null
      ),
    [layers]
  );

  useEffect(() => {
    randomizeBlueprint();
  }, [randomizeBlueprint]);

  return {
    builderBlueprint,
    modifyBlueprint,
    randomizeBlueprint,
  };
};

const calculateCoordinates = (e: MouseEvent | DragEvent, canvas: HTMLCanvasElement): MousePosition => {
  const rect = canvas.getBoundingClientRect();
  const xFactor = canvas.width / rect.width;
  const yFactor = canvas.height / rect.height;
  const x = xFactor * (e.clientX - rect.left);
  const y = yFactor * (e.clientY - rect.top);
  return { x, y };
};

const isWithinCanvas = (position: MousePosition, canvas: HTMLCanvasElement) => {
  return position.x > 0 && position.x < canvas.width && position.y > 0 && position.y < canvas.height;
};

export interface MousePosition {
  x: number;
  y: number;
}

export const usePainter = (canvas: HTMLCanvasElement | null, editMode: EditMode) => {
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<MousePosition | null>(null);
  const [penSize, setPenSize] = useState(5);
  const [penColor, setPenColor] = useState('#121212');

  const readyForInteraction = editMode === EditMode.PEN;

  const onPainterMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!readyForInteraction || !isPainting || canvas == null) {
        return;
      }
      const context = canvas.getContext('2d');
      if (context == null) {
        return;
      }
      if (mousePosition == null) {
        return;
      }
      const newMousePosition = calculateCoordinates(e, canvas);

      context.lineCap = 'round';
      context.strokeStyle = penColor;
      context.lineWidth = penSize;

      context.beginPath();
      context.moveTo(mousePosition.x, mousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.stroke();
      context.closePath();
      context.fill();

      setMousePosition(newMousePosition);
    },
    [canvas, isPainting, mousePosition, penColor, penSize, readyForInteraction]
  );

  const onPainterMouseDown = useCallback(
    (e: MouseEvent): void => {
      if (!readyForInteraction || canvas == null) {
        return;
      }
      setIsPainting(true);
      const coordinates = calculateCoordinates(e, canvas);
      setMousePosition(coordinates);
    },
    [canvas, readyForInteraction]
  );

  const onPainterMouseUp = (): void => {
    setIsPainting(false);
  };

  const onPainterMouseLeave = (): void => {
    setIsPainting(false);
  };

  return {
    isPainting,
    onPainterMouseMove,
    onPainterMouseDown,
    onPainterMouseUp,
    onPainterMouseLeave,
    penSize,
    setPenSize,
    penColor,
    setPenColor,
  };
};

interface QueuedSticker {
  image: HTMLImageElement;
  position: MousePosition;
}

export const useStickerStamper = (workshopName: string, canvas: HTMLCanvasElement | null, editMode: EditMode) => {
  const [queuedStickers, setQueuedStickers] = useState<QueuedSticker[]>([]);
  const [selectedSticker, setSelectedSticker] = useState<string | null>(null);
  const { isFetchingStickers, stickerOptions } = useStickerStamperImageOptions(workshopName);
  const cachingImages = usePreloadImages(stickerOptions ?? [], false);

  const readyForInteraction = editMode === EditMode.STICKER && !isFetchingStickers && !cachingImages;

  const selectSticker = (sticker: string) => {
    setSelectedSticker(sticker);
  };

  const clearStickerQueue = () => {
    setQueuedStickers([]);
  };

  const addSelectedStickerToQueue = useCallback(
    (position: MousePosition) => {
      const cachedImage = selectedSticker != null ? window.preloadImagesData?.[selectedSticker] : null;
      if (cachedImage != null) {
        setQueuedStickers((stickers) =>
          stickers.concat([
            {
              image: cachedImage,
              position,
            },
          ])
        );
      }
    },
    [selectedSticker]
  );

  const onStickerCanvasClick = useCallback(
    (e: MouseEvent) => {
      if (!readyForInteraction || canvas == null) {
        return;
      }
      const position = calculateCoordinates(e, canvas);
      if (isWithinCanvas(position, canvas)) {
        addSelectedStickerToQueue(position);
      }
    },
    [addSelectedStickerToQueue, canvas, readyForInteraction]
  );

  return {
    cachingImages,
    isFetchingStickers,
    stickerOptions,
    queuedStickers,
    selectSticker,
    clearStickerQueue,
    onStickerCanvasClick,
  };
};

const useStickerStamperImageOptions = (workshopName: string) => {
  const [stickerOptions, setStickerOptions] = useState<string[] | null>([]);
  const [isFetchingStickers, setIsFetchingStickers] = useState(false);

  const fetchStickerOptions = useCallback(async () => {
    console.log(workshopName);
    setIsFetchingStickers(true);
    setStickerOptions(await getStickerStamperAssets(workshopName));
    setIsFetchingStickers(false);
  }, [workshopName]);

  useEffect(() => {
    console.log('fetchsticker');
    fetchStickerOptions();
  }, [fetchStickerOptions]);

  return {
    isFetchingStickers,
    stickerOptions,
  };
};

type WorkshopContextType = ReturnType<typeof useWorkshop>;

const WorkshopContext = createContext<WorkshopContextType | null>(null);

export const useWorkshopContext = (): WorkshopContextType => {
  const workshopContext = useContext(WorkshopContext);
  if (workshopContext == null) {
    throw new Error('No workshop context; are you missing an <WorkshopContextProvider />?');
  }
  return workshopContext;
};

interface WorkshopContextProviderProps {
  workshopKey: string;
}

export const WorkshopContextProvider = ({
  children,
  workshopKey,
}: PropsWithChildren<WorkshopContextProviderProps>): ReactElement => {
  const workshopFlow = useWorkshop(workshopKey);

  return <WorkshopContext.Provider value={workshopFlow}>{children}</WorkshopContext.Provider>;
};
