import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import navigationReducer, { NavigationAction } from "./navigation/reducer";
import thunk, { ThunkDispatch } from "redux-thunk";

const rootReducer = combineReducers({
  navigation: navigationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, unknown, NavigationAction> &
  typeof store.dispatch;
