import { Flex } from "@chakra-ui/react";
import { PropsWithChildren, ReactElement } from "react";
import { useDelayedOpening } from "../../../hooks/optimization";
import Placeholder from "../Loading/Placeholder";

interface ContainerWithPlaceholderProps extends PropsWithChildren {
  loading: boolean;
}

const ContainerWithPlaceholder = ({
  children,
  loading,
}: ContainerWithPlaceholderProps): ReactElement => {
  const showChildren = useDelayedOpening([!loading]);

  return showChildren ? (
    <>{children}</>
  ) : (
    <Flex
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Placeholder />
    </Flex>
  );
};

export default ContainerWithPlaceholder;
