import { ReactElement } from "react";
import { BOBA_DRINK_IMAGE_URL } from "../../../../Constants";
import IllustratedDescription from "../../../Common/IllustratedDescription/IllustratedDescription";

const Boba = (): ReactElement => {
  const description =
    "Boba tea (or bubble tea) is a tea-based drink that originated in Taiwan in the early 1980s. " +
    "It most commonly consists of tea accompanied by chewy tapioca balls, as well as additional toppings. The tea " +
    "base for boba drinks is usually black or green tea and can be customized with an array of syrups like peach, " +
    "strawberry, and lychee! Beyond teas, most boba shops also have slushies and milk drinks available. With Boba culture " +
    "now asserting dominance in global markets, you can also take part in this festivity of utmost euphoria!";

  return (
    <IllustratedDescription
      description={description}
      header="Boba What...?"
      imageSrc={BOBA_DRINK_IMAGE_URL}
    />
  );
};

export default Boba;
