import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../store";
import { NavigationAction } from "./reducer";
import NavigationActionType from "./types";

export const openConnectorModal =
  (): ThunkAction<void, RootState, unknown, NavigationAction> =>
  (dispatch: ThunkDispatch<RootState, unknown, NavigationAction>) => {
    dispatch({
      type: NavigationActionType.OPEN_CONNECTOR_MODAL,
    });
  };

export const closeConnectorModal =
  (): ThunkAction<void, RootState, unknown, NavigationAction> =>
  (dispatch: ThunkDispatch<RootState, unknown, NavigationAction>) => {
    dispatch({
      type: NavigationActionType.CLOSE_CONNECTOR_MODAL,
    });
  };
