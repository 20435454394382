import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Colors, Fonts } from '../../../Constants';

interface IllustratedDescriptionProps {
  description: string;
  header: string;
  imageSrc: string;
}

const IllustratedDescription = ({ description, header, imageSrc }: IllustratedDescriptionProps): ReactElement => {
  return (
    <Stack alignItems="center" direction={{ base: 'column', lg: 'row' }} textAlign={{ base: 'center', lg: 'left' }}>
      <Flex
        flex={{ lg: 1 }}
        position="relative"
        justifyContent="center"
        alignItems="center"
        height={{ base: '40vh', md: '67vh', lg: '77vh' }}>
        <Image src={imageSrc} />
      </Flex>
      <Flex alignItems="center" flex={1}>
        <Stack>
          <Text
            color={Colors.Text.HEADER_SECONDARY}
            fontFamily={Fonts.Header.SECONDARY}
            fontSize={{ base: 'xl', xl: '4xl' }}>
            {header}
          </Text>
          <Text>{description}</Text>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default IllustratedDescription;
