import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement } from "react";

const SpinnerModal = ({ isOpen }: { isOpen: boolean }): ReactElement => {
  const { onClose } = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent alignItems="center" bgColor="transparent" boxShadow="none">
        <Spinner size="xl" color="white" />
      </ModalContent>
    </Modal>
  );
};

export default SpinnerModal;
