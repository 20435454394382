import { Image, useToast } from '@chakra-ui/react';
import { ReactElement, useEffect } from 'react';
import { usePreloadImages } from '../../hooks/optimization';
import { CLOUD_MULTITUDE_IMAGE_URL, HOME_PAGE_PRELOAD_IMAGE_URLS } from '../../Constants';
import Banner from './Banner/Banner';
import Team from './Team/Team';
import Faq from './Faq/Faq';
import ProjectDescriptions from './Project/Project';
import Spacer from '../Common/Spacer/Spacer';
import GlobalContainerWithNav from '../Common/Container/GlobalContainerWithNav';
import ContainerWithPlaceholder from '../Common/Container/ContainerWithPlaceholder';

const HomePage = (): ReactElement | null => {
  const loading = usePreloadImages(HOME_PAGE_PRELOAD_IMAGE_URLS);
  const toast = useToast();
  const errorMsg = null;

  useEffect(() => {
    if (!loading && errorMsg != null) {
      toast({
        title: 'Blockchain Network Error',
        description: errorMsg,
        status: 'error',
        duration: null,
        isClosable: true,
        position: 'bottom-right',
        containerStyle: {
          color: 'white',
        },
      });
    }
  }, [loading, errorMsg, toast]);

  return (
    <ContainerWithPlaceholder loading={loading}>
      <GlobalContainerWithNav>
        <Banner />
        <Spacer size={12} />
        <ProjectDescriptions />
        <Spacer size="5vh" />
        <Image src={CLOUD_MULTITUDE_IMAGE_URL} />
        <Spacer size="10vh" />
        <Team />
        <Spacer size={12} />
        <Faq />
        <Spacer size={28} />
      </GlobalContainerWithNav>
    </ContainerWithPlaceholder>
  );
};

export default HomePage;
