import { Grid, GridItem, Image, Link, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { TEAM_MEMBERS } from '../../../Constants';
import { useFadeInComponent } from '../../../hooks/animation';
import Header from '../../Common/Header/Header';

export interface TeamMember {
  name: string;
  role: string;
  imageSrc: string;
  link?: string;
}

const Team = (): ReactElement => {
  const fadeInProps = useFadeInComponent();

  const columns = useBreakpointValue({
    base: 2,
    sm: 4,
  });

  return (
    <VStack id="team" spacing={14} width={{ base: '90%', lg: '50%' }} {...fadeInProps}>
      <Header>Our Team</Header>
      <Grid columnGap="3vw" rowGap="5vh" templateColumns={`repeat(${columns}, 1fr)`} pb={20}>
        {TEAM_MEMBERS.map((member) => (
          <GridItem>
            <VStack key={member.name} textAlign="center">
              <Link href={member.link} isExternal _focus={{ outline: 'none' }}>
                <Image
                  src={member.imageSrc}
                  cursor="var(--cursor-pointer)"
                  borderRadius={14}
                  transitionDuration="0.15s"
                  _hover={{ transform: 'scale(1.04, 1.04)', boxShadow: '0px 2px 8px 2px #d2d2d263' }}
                />
              </Link>
              <Text fontSize={{ base: 'sm', md: 'lg' }} fontWeight="bold" pt={3}>
                {member.name}
              </Text>
              <Text>{member.role}</Text>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </VStack>
  );
};

export default Team;
