import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  forwardRef,
  Link,
  VStack,
} from '@chakra-ui/react';
import { memo, PropsWithChildren, ReactElement } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { LINK_TYPE } from '../constants';
import { NavDropdownType, NavItemType, NavLinkType } from '../types';

const NavItemContainer = forwardRef((props: PropsWithChildren, ref): ReactElement => {
  return (
    <Box ref={ref} {...props}>
      {props.children}
    </Box>
  );
});

const LinkItem = ({ navItem }: { navItem: NavLinkType }): ReactElement => {
  return (
    <Link
      href={navItem.href}
      _hover={{ textDecoration: 'none' }}
      style={{ marginTop: 0 }}
      isExternal={navItem.isExternal === true}>
      <NavItemContainer>{navItem.label}</NavItemContainer>
    </Link>
  );
};

const ScrollItem = ({ navItem }: { navItem: NavLinkType }): ReactElement => {
  return (
    <ScrollLink to={navItem.href} smooth={true} delay={0} duration={500} style={{ marginTop: 0 }}>
      <NavItemContainer>{navItem.label}</NavItemContainer>
    </ScrollLink>
  );
};

const MenuDropdown = ({ account, navItem }: { account: string | null; navItem: NavDropdownType }): ReactElement => {
  return (
    <Accordion allowMultiple style={{ marginTop: 0 }}>
      <AccordionItem border="none">
        <AccordionButton as={NavItemContainer} justifyContent="center" p={0} _hover={{ background: 'transparent' }}>
          {navItem.label}
          <Box position="relative">
            <ChevronDownIcon position="absolute" bottom={-2} right={-5} />
          </Box>
        </AccordionButton>
        <AccordionPanel mt={2}>
          <VStack gap={2}>
            {navItem.children.map((child) => (
              <NavBarItem key={child.label} account={account} navItem={child} />
            ))}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

interface NavItemProps {
  account: string | null;
  navItem: NavItemType;
}

const NavBarItem = memo(({ account, navItem }: NavItemProps): ReactElement | null => {
  switch (navItem.type) {
    case LINK_TYPE.LINK:
      return <LinkItem navItem={navItem} />;
    case LINK_TYPE.SCROLL:
      return <ScrollItem navItem={navItem} />;
    case LINK_TYPE.MENU:
      return <MenuDropdown navItem={navItem} account={account} />;
    default:
      return null;
  }
});

export default NavBarItem;
