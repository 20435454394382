import { StackProps, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Navbar from '../Nav/Navbar';
import Spacer from '../Spacer/Spacer';

interface GlobalContainerWithNavProps extends StackProps {
  hideFooterClouds?: boolean;
}

const GlobalContainerWithNav = ({
  hideFooterClouds = false,
  children,
  ...props
}: GlobalContainerWithNavProps): ReactElement => {
  const { pathname } = useLocation();
  const shouldAddTopSpacer = pathname !== '/';

  return (
    <VStack fontSize={{ base: '14px', md: '14px', lg: '15px', xl: '16px' }} pt={props.pt ?? '100px'} {...props}>
      <Navbar />
      {shouldAddTopSpacer && <Spacer size={6} />}
      {children}
      {!hideFooterClouds && <Footer />}
    </VStack>
  );
};

export default GlobalContainerWithNav;
