import { Flex, Image, Stack, StackProps, useBreakpointValue } from '@chakra-ui/react';
import { animated, useTransition } from '@react-spring/web';
import React, { Children, PropsWithChildren, ReactElement, ReactNode, useState } from 'react';

interface CarouselArrowProps {
  direction: 'left' | 'right';
  onClick: () => void;
  disabled?: boolean;
}

const CarouselArrow = React.memo(({ direction, onClick, disabled }: CarouselArrowProps): ReactElement => {
  const imageSrc = `/accessories/arrow_${direction}.png`;
  const [isHovered, setIsHovered] = useState(false);
  const size = useBreakpointValue({
    base: '40px',
    md: '70px',
  });

  const useHoverProps = !disabled && isHovered;

  const arrowProps = {
    width: size,
    transitionDuration: '0.5s',
    filter: useHoverProps ? 'hue-rotate(-60deg)' : undefined,
    cursor: useHoverProps ? 'var(--cursor-pointer)' : disabled ? 'not-allowed' : 'var(--cursor-default)',
  };

  return (
    <Flex alignItems="center">
      <Image
        onClick={disabled ? undefined : onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        src={imageSrc}
        {...arrowProps}
      />
    </Flex>
  );
});

const Carousel = ({ children, ...props }: StackProps & PropsWithChildren): ReactElement => {
  const childrenArray = Children.toArray(children);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(0);

  const transition = useTransition(selectedComponentIndex, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      duration: 200,
    },
    exitBeforeEnter: true,
  });

  const handleSlideLeftClick = (): void => {
    setSelectedComponentIndex((currentIndex) => Math.max(currentIndex - 1, 0));
  };

  const handleSlideRightClick = (): void => {
    setSelectedComponentIndex((currentIndex) => Math.min(currentIndex + 1, childrenArray.length - 1));
  };

  const isLeftSlideDisabled = selectedComponentIndex === 0;
  const isRightSlideDisabled = selectedComponentIndex === childrenArray.length - 1;

  const renderSelectedComponent = (index: number): ReactNode => {
    return childrenArray[index];
  };

  return (
    <Stack direction="row" spacing={{ base: 2, md: 5 }} {...props}>
      <CarouselArrow direction="left" onClick={handleSlideLeftClick} disabled={isLeftSlideDisabled} />
      <Flex w="100%">
        {transition((styles, index) => (
          <animated.div style={styles}>{renderSelectedComponent(index)}</animated.div>
        ))}
      </Flex>
      <CarouselArrow direction="right" onClick={handleSlideRightClick} disabled={isRightSlideDisabled} />
    </Stack>
  );
};

export default Carousel;
