import { configureChains, createClient, goerli, mainnet } from 'wagmi';
import { infuraProvider } from 'wagmi/providers/infura';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

export enum ConnectionError {
  USER_REJECTED_REQUEST = 4001,
  RESOURCE_UNAVAILABLE = -32002,
}

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY ?? '' })]
);

export const connectorsClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Boba Inu',
      },
    }),
  ],
  provider,
  webSocketProvider,
});
