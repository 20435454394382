import { StatusCodes } from 'http-status-codes';
import { WorkshopBuilderLayerType, WorkshopType } from './types';
import HttpUtils from '../../utils/http';
import { NAV_LOGO_IMAGE_URL } from '../../Constants';

export const getWorkshops = async (): Promise<WorkshopType[] | null> => {
  const response = await HttpUtils.get('build_a_bobo/workshops');
  if (response.status !== StatusCodes.OK) {
    return null;
  }
  return (await response.json()) as WorkshopType[];
};

export const getLayersAndAssetsForWorkshop = async (
  workshopKey: string
): Promise<WorkshopBuilderLayerType[] | null> => {
  const response = await HttpUtils.get(`build_a_bobo/assets/${workshopKey}`);
  if (response.status !== StatusCodes.OK) {
    return null;
  }
  return (await response.json()) as WorkshopBuilderLayerType[];
};

export const getStickerStamperAssets = async (workshopKey: string): Promise<string[] | null> => {
  if (workshopKey == null) {
    return null;
  }
  return [NAV_LOGO_IMAGE_URL];
};
