import { ChakraProvider } from "@chakra-ui/react";
import { WagmiConfig } from "wagmi";
import { connectorsClient } from "./web3/connectors";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./chakra/theme.js";
import * as buffer from "buffer";
import "./index.css";

declare global {
  interface Window {
    preloadImagesData: Record<string, HTMLImageElement> | null;
  }
}

window.Buffer = buffer.Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvider store={store}>
    <WagmiConfig client={connectorsClient}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </WagmiConfig>
  </ReduxProvider>
);
