import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { PAGE_NOT_FOUND_IMAGE_URL } from "../../Constants";
import Button from "../Common/Button/Button";

const PageNotFound = (): ReactElement => {
  return (
    <Flex justifyContent="center">
      <VStack
        width="300px"
        justifyContent="center"
        textAlign="center"
        spacing={12}
      >
        <Image src={PAGE_NOT_FOUND_IMAGE_URL} height="300px" />
        <Text fontSize="lg">
          Hey, mischievous mut. Where do you think you're wandering off to?
        </Text>
        <Button as={Link} to="/" buttonSize="lg">
          Go Home
        </Button>
      </VStack>
    </Flex>
  );
};

export default PageNotFound;
