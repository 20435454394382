import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../hooks/redux';
import { openConnectorModal } from '../../../redux/navigation/action';
import { LINK_TYPE } from './constants';
import { NavLinkType, NavCustomType } from './types';
import { useWeb3Connect } from '../../../hooks/web3';
import Button from '../Button/Button';
import NavBarItem from './Desktop/NavBarItem';

const Web3Connector = () => {
  const { isConnecting, isConnected, connectedWalletAddress, disconnect } = useWeb3Connect();
  const dispatch = useAppDispatch();
  const formatConnectedWalletAddress = useCallback(() => {
    return connectedWalletAddress
      ? `${connectedWalletAddress.substring(0, 5)}...${connectedWalletAddress.substring(
          connectedWalletAddress.length - 4
        )}`
      : null;
  }, [connectedWalletAddress]);

  const dropdownItems: (NavLinkType | NavCustomType)[] = [
    {
      label: 'Disconnect',
      type: LINK_TYPE.CUSTOM,
      onClick: disconnect,
    },
  ];

  const handleWalletButtonClick = () => {
    dispatch(openConnectorModal());
  };

  return isConnected ? (
    <Menu>
      <MenuButton as={Button}>
        {formatConnectedWalletAddress()}
        <ChevronDownIcon />
      </MenuButton>
      <MenuList borderRadius={10} p="6px">
        {dropdownItems.map((item) => (
          <NavBarItem key={item.label} account={connectedWalletAddress} navItem={item} />
        ))}
      </MenuList>
    </Menu>
  ) : (
    <Button onClick={handleWalletButtonClick}>{isConnecting ? 'Connecting...' : 'Connect Wallet'}</Button>
  );
};

export default Web3Connector;
