import { Box, Image, keyframes } from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  BOAT_IMAGE_URL,
  WAVE_IMAGE_URL,
  WHITE_CLOUD_RIGHT_IMAGE_URL,
} from "../../../../Constants";
import { useFadeInComponent } from "../../../../hooks/animation";

const BoBoat = (): ReactElement => {
  const fadeInProps = useFadeInComponent();

  const boatAnimationKeyframes = keyframes`
    0% {
      transform: translateY(2%) rotate(-1deg);
    }
    100% {
      transform: translateY(-2%) rotate(1deg);
    }
  `;

  const boatAnimation = `${boatAnimationKeyframes} 2s ease-in-out alternate infinite`;

  const cloudAnimationKeyframes = keyframes`
    0% {
      transform: translateX(15%);
    }
    100% {
      transform: translateX(0%);
    }
  `;

  const cloudAnimation = `${cloudAnimationKeyframes} 3s ease-in-out alternate infinite`;

  return (
    <Box {...fadeInProps}>
      <Image
        animation={cloudAnimation}
        position="absolute"
        right="40vw"
        src={WHITE_CLOUD_RIGHT_IMAGE_URL}
        top="24vh"
      />
      <Image
        animation={boatAnimation}
        height={{
          base: "35vh",
          sm: "45vh",
          md: "50vh",
          lg: "55vh",
          xl: "60vh",
        }}
        position="absolute"
        right="-4vw"
        src={BOAT_IMAGE_URL}
        top={{
          base: "16vh",
          sm: "6vh",
          md: "6vh",
          lg: "6vh",
          xl: "6vh",
        }}
      />
      <Image
        animation={boatAnimation}
        height={{
          base: "22vh",
          sm: "27vh",
          md: "29vh",
          lg: "31vh",
          xl: "33vh",
        }}
        position="absolute"
        right="-4vw"
        src={WAVE_IMAGE_URL}
        top={{
          base: "42vh",
          sm: "40vh",
          md: "44vh",
          lg: "48vh",
          xl: "52vh",
        }}
      />
    </Box>
  );
};

export default BoBoat;
