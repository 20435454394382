import { ReactElement } from "react";
import { BOBO_FAMILY_IMAGE_URL } from "../../../../Constants";
import IllustratedDescription from "../../../Common/IllustratedDescription/IllustratedDescription";

const Community = (): ReactElement => {
  const description =
    "We are a band of boba freaks and dog fanatics! Furthermore, our values center around " +
    "anyone who might be far from home, and miss the joys of family, tradition, and food. Bobo feels. Bobo knows. " +
    "Through Boba Inu, we are building a community of personal identity and inclusion. We want our fans to know " +
    "that we are committed to the long term expansion of our brand through full transparency and good old grit. " +
    "Be like Bobo. Build with us!";

  return (
    <IllustratedDescription
      description={description}
      header="Our Community"
      imageSrc={BOBO_FAMILY_IMAGE_URL}
    />
  );
};

export default Community;
