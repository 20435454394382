import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FAQ_ITEMS } from '../../../Constants';
import { useFadeInComponent } from '../../../hooks/animation';
import Header from '../../Common/Header/Header';

const Faq = (): ReactElement => {
  const fadeInProps = useFadeInComponent();

  return (
    <Stack id="faq" spacing="5vh" width={{ base: '90%', lg: '50%' }} {...fadeInProps}>
      <Header>FAQ</Header>
      <Accordion allowToggle>
        {FAQ_ITEMS.map((item, index) => (
          <AccordionItem key={index} borderTop="none" borderBottom="1px solid var(--text-normal)" py={2}>
            <Heading fontFamily="'Poppins', serif" fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}>
              <AccordionButton _focus={{ outline: 'none' }} cursor="var(--cursor-pointer)">
                <Box flex={1} fontWeight="bold" textAlign="left">
                  {item.q}
                </Box>
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4} textAlign="left">
              {item.a}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
};

export default Faq;
