import { ethers } from 'ethers';
import { useAccount, useConnect, useContractRead, useDisconnect, useNetwork } from 'wagmi';
import { BOBA_INU_SMART_CONTRACT_ADDRESS } from '../../Constants';
import abi from '../../web3/smartContract/abi';

export enum MintRole {
  OG,
  COLLAB_WL,
  COMMUNITY_WL,
}

export const useWeb3Connect = () => {
  const { address, isConnected } = useAccount();
  const { connect, connectors, status, error, pendingConnector, isLoading } = useConnect();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();

  const isConnectedToMainnet = !isLoading && isConnected && address != null && chain?.name === 'Ethereum';

  return {
    connect,
    disconnect,
    connectors,
    isConnecting: isLoading,
    pendingConnector,
    status,
    isConnected,
    isConnectedToMainnet,
    error,
    connectedWalletAddress: address ?? null,
    connectedChain: chain,
  };
};

export const useNftOwnership = (walletAddress: string | null) => {
  const { data } = useContractRead({
    abi,
    address: BOBA_INU_SMART_CONTRACT_ADDRESS,
    functionName: walletAddress != null ? 'balanceOf' : undefined,
    args: walletAddress != null ? [ethers.utils.getAddress(walletAddress)] : undefined,
    watch: false,
    cacheTime: 120000,
  });
  const quantityOwned = data?.toNumber();
  const isOwner = quantityOwned != null && quantityOwned > 0;

  return {
    isOwner,
    quantityOwned,
  };
};
