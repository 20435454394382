import { ReactElement } from "react";
import { INUS_WALL_PHOTOS_IMAGE_URL } from "../../../../Constants";
import IllustratedDescription from "../../../Common/IllustratedDescription/IllustratedDescription";

const Intro = (): ReactElement => {
  const description =
    "Boba Inu is a collection of unique non-fungible tokens " +
    "existing on the Ethereum blockchain. Each NFT is a snapshot from " +
    "one of Bobo's many adventures as he travels the world in search of " +
    "Boba frens. With each NFT, you might spot references to different " +
    "countries, cultures, and peoples. Our artist has designed more than 200 traits, " +
    "such as - teas, cups, straws, outfits, and accessories - represent all our inner " +
    "inus. Though separated, we are all united through our love of Boba!";

  return (
    <IllustratedDescription
      description={description}
      header="What is Boba Inu?"
      imageSrc={INUS_WALL_PHOTOS_IMAGE_URL}
    />
  );
};

export default Intro;
