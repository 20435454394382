import NavigationActionType from "./types";

export type NavigationState = {
  isConnectorModalOpen: boolean;
};

export type NavigationAction = {
  type: NavigationActionType;
  payload?: NavigationState | null;
};

const initialState = {
  isConnectorModalOpen: false,
};

const reducer = (
  state: NavigationState = initialState,
  action: NavigationAction
): NavigationState => {
  switch (action.type) {
    case NavigationActionType.OPEN_CONNECTOR_MODAL:
      return {
        ...state,
        isConnectorModalOpen: true,
      };
    case NavigationActionType.CLOSE_CONNECTOR_MODAL:
      return {
        ...state,
        isConnectorModalOpen: false,
      };
    default:
      return state;
  }
};

export default reducer;
