import { HStack, IconButton, Image, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CLOUD_MULTITUDE_IMAGE_URL, LOGO_WITH_BOBO_IMAGE_URL } from '../../../Constants';
import { NAV_SOCIAL_ICONS } from '../Nav/Navbar';
import Spacer from '../Spacer/Spacer';

interface FooterProps {
  hideClouds?: boolean;
}

const Footer = ({ hideClouds = false }: FooterProps): ReactElement => {
  return (
    <VStack align="center" justifyContent="center" textAlign="center" pb="16vh" spacing={4}>
      {!hideClouds && <Image src={CLOUD_MULTITUDE_IMAGE_URL} />}
      <Spacer size={16} />
      <VStack w={{ base: '80%', lg: '100%' }}>
        <Image src={LOGO_WITH_BOBO_IMAGE_URL} maxH="160px" minW="100px" />
        <Text>Copyright © Boba Inu 2022. All rights reserved.</Text>
        <HStack spacing={{ base: 2, md: 4 }} py={{ base: 5, md: 0 }}>
          {NAV_SOCIAL_ICONS.map((nav) => (
            <LinkBox key={nav.href}>
              <LinkOverlay href={nav.href} isExternal>
                <IconButton
                  cursor="var(--cursor-pointer)"
                  variant="ghost"
                  color="#693722"
                  aria-label="twitter"
                  _hover={{
                    bgColor: 'transparent',
                    transitionDuration: '0.5s',
                  }}
                  icon={nav.icon}
                />
              </LinkOverlay>
            </LinkBox>
          ))}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Footer;
