import { ReactElement } from 'react';
import { WORKSHOP_IMAGE_URL } from '../../../../Constants';
import IllustratedDescription from '../../../Common/IllustratedDescription/IllustratedDescription';

const Workshop = (): ReactElement => {
  const description =
    "A token-gated PFP designer application will be made available for holders to use. This app will allow holders to design and create their own personalized Bobo PFPs using a variety of templates and stylers. These templates will be distinct from the main NFT collection art, and you'll be able to pick your favorite facial expressions, outfits, accessories, and background colors. Once completed, you'll have the option to mint your PFPs by paying a small gas fee. Additionally, you can also request to have your PFP design printed on custom merchandise and delivered to you for only the manufacture cost plus shipping.";

  return (
    <IllustratedDescription description={description} header="Build a Bobo Workshop" imageSrc={WORKSHOP_IMAGE_URL} />
  );
};

export default Workshop;
