import { Heading, HeadingProps, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Colors, Fonts } from '../../../Constants';

interface HeaderProps extends HeadingProps {
  subheader?: string;
}

const Header = ({ subheader, children, ...props }: HeaderProps): ReactElement => {
  return (
    <Heading
      color={Colors.Text.HEADER_PRIMARY}
      textAlign="center"
      fontFamily={Fonts.Header.PRIMARY}
      fontSize={{ base: '2xl', lg: '4xl', xl: '5xl' }}
      fontWeight="normal"
      {...props}>
      {children}
      <Text fontFamily={Fonts.Normal} fontSize={{ base: 'sm', lg: 'lg', xl: 'xl' }} fontWeight="bold">
        {subheader}
      </Text>
    </Heading>
  );
};

export default Header;
