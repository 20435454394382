import { ReactElement } from "react";
import { BOBO_BOBA_IMAGE_URL } from "../../../../Constants";
import IllustratedDescription from "../../../Common/IllustratedDescription/IllustratedDescription";

const Merchandise = (): ReactElement => {
  const description =
    "Our vision is for Boba Inu to not just be another art collection. " +
    "Proceeds from mint will be used to develop and market tangible products and form " +
    "partnerships, allowing you to wear, live, and breathe Boba Inu! Your NFTs will serve " +
    "as a gateway to perks and discounted rates. Sooner or later, Boba Inus will be seeing " +
    "each other on the streets. Boba Inu forever!";

  return (
    <IllustratedDescription
      description={description}
      header="Merchandising"
      imageSrc={BOBO_BOBA_IMAGE_URL}
    />
  );
};

export default Merchandise;
