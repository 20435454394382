import { Stack, useBreakpointValue } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useFadeInComponent } from '../../../hooks/animation';
import Carousel from '../../Common/Animation/Carousel/Carousel';
import Header from '../../Common/Header/Header';
import Intro from './Intro/Intro';
import Boba from './Boba/Boba';
import Community from './Community/Community';
import Goals from './Goals/Goals';
import Merchandise from './Merchandise/Merchandise';
import Workshop from './Workshop/Workshop';

const ProjectDescriptions = (): ReactElement => {
  const fadeInProps = useFadeInComponent();
  const carouselHeight = useBreakpointValue({
    base: '100%',
    lg: '40vw',
  });

  return (
    <Stack id="project" mb="10vh" pt={5} width="90%" {...fadeInProps}>
      <Header>The Project</Header>
      <Carousel minHeight={carouselHeight}>
        <Intro />
        <Boba />
        <Goals />
        <Community />
        <Merchandise />
        <Workshop />
      </Carousel>
    </Stack>
  );
};

export default ProjectDescriptions;
