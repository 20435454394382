import { Image, VStack } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { BUILD_A_BOBO_WELCOME_GIF_URL, CLOUD_MULTITUDE_IMAGE_URL, Colors } from '../../../Constants';
import { useBuildABoboWorkshops, Workshop as WorkshopType } from '../../../hooks/build_a_bobo';
import { usePreloadImages } from '../../../hooks/optimization';
import Button from '../../Common/Button/Button';
import Card from '../../Common/Container/Card';
import ContainerWithPlaceholder from '../../Common/Container/ContainerWithPlaceholder';
import GlobalContainerWithNav from '../../Common/Container/GlobalContainerWithNav';
import Header from '../../Common/Header/Header';
import Spacer from '../../Common/Spacer/Spacer';
import Workshop from './Workshop';

interface WelcomeViewProps {
  onClick: () => void;
}

const WelcomeView = ({ onClick }: WelcomeViewProps): ReactElement => {
  return (
    <>
      <Header color={Colors.Text.NORMAL_SECONDARY}>Build A Bobo</Header>
      <Spacer size={6} />
      <Card width={{ base: '100%', sm: '80%', md: '60%', lg: '30%' }} p={5}>
        <VStack spacing={8}>
          <Image src={BUILD_A_BOBO_WELCOME_GIF_URL} borderRadius={10} />
          <Button variant="rounded" buttonSize="lg" onClick={onClick}>
            Start Building
          </Button>
        </VStack>
      </Card>
    </>
  );
};

const BuildABobo = (): ReactElement => {
  const workshops = useBuildABoboWorkshops();
  const [selectedWorkshop, setSelectedWorkshop] = useState<WorkshopType | null>(null);
  const [isWorkshopOpen, setIsWorkshopOpen] = useState(false);
  const loadingGif = usePreloadImages([BUILD_A_BOBO_WELCOME_GIF_URL]);
  const openWorkshopHandler = (): void => {
    setIsWorkshopOpen(true);
  };

  useEffect(() => {
    if (workshops.length > 0) {
      setSelectedWorkshop(workshops[0]);
    }
  }, [workshops]);

  return (
    <ContainerWithPlaceholder loading={loadingGif}>
      <GlobalContainerWithNav hideFooterClouds>
        <VStack width="80%" zIndex={1}>
          {isWorkshopOpen ? (
            selectedWorkshop != null ? (
              <Workshop workshop={selectedWorkshop} />
            ) : null
          ) : (
            <WelcomeView onClick={openWorkshopHandler} />
          )}
        </VStack>
        <Image
          src={CLOUD_MULTITUDE_IMAGE_URL}
          position={{ base: 'inherit', md: 'absolute' }}
          top={{ base: 'none', md: '35%' }}
        />
      </GlobalContainerWithNav>
    </ContainerWithPlaceholder>
  );
};

export default BuildABobo;
