import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, BoxProps, forwardRef, Link, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { memo, ReactElement } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { LINK_TYPE } from '../constants';
import { NavCustomType, NavDropdownType, NavItemType, NavLinkType } from '../types';

const NavItemContainer = forwardRef((props: BoxProps, ref): ReactElement => {
  return (
    <Box
      ref={ref}
      cursor="var(--cursor-pointer)"
      _hover={{
        textDecoration: 'none',
        backgroundColor: '#693722',
        color: 'white',
        transitionDuration: '0.25s',
      }}
      fontSize="md"
      px={2}
      py={1}
      borderRadius={10}
      fontWeight={600}
      {...props}>
      {props.children}
    </Box>
  );
});

const LinkItem = ({ navItem }: { navItem: NavLinkType }): ReactElement => {
  return (
    <Link
      href={navItem.href}
      _hover={{ textDecoration: 'none' }}
      style={{ marginTop: 0 }}
      isExternal={navItem.isExternal === true}>
      <NavItemContainer>{navItem.label}</NavItemContainer>
    </Link>
  );
};

const ScrollItem = ({ navItem }: { navItem: NavLinkType }): ReactElement => {
  return (
    <ScrollLink to={navItem.href} smooth={true} delay={0} duration={500} style={{ marginTop: 0 }}>
      <NavItemContainer>{navItem.label}</NavItemContainer>
    </ScrollLink>
  );
};

const CustomItem = ({ navItem }: { navItem: NavCustomType }): ReactElement => {
  return <NavItemContainer onClick={navItem.onClick}>{navItem.label}</NavItemContainer>;
};

const MenuDropdown = ({ account, navItem }: { account: string | null; navItem: NavDropdownType }): ReactElement => {
  return (
    <Menu>
      <MenuButton as={NavItemContainer}>
        {navItem.label}
        <ChevronDownIcon />
      </MenuButton>
      <MenuList borderRadius={10} p="6px">
        {navItem.children.map((child) => (
          <NavBarItem key={child.label} account={account} navItem={child} />
        ))}
      </MenuList>
    </Menu>
  );
};

interface NavItemProps {
  account: string | null;
  navItem: NavItemType;
}

const NavBarItem = memo(({ account, navItem }: NavItemProps): ReactElement | null => {
  switch (navItem.type) {
    case LINK_TYPE.LINK:
      return <LinkItem navItem={navItem} />;
    case LINK_TYPE.SCROLL:
      return <ScrollItem navItem={navItem} />;
    case LINK_TYPE.CUSTOM:
      return <CustomItem navItem={navItem} />;
    case LINK_TYPE.MENU:
      return <MenuDropdown navItem={navItem} account={account} />;
    default:
      return null;
  }
});

export default NavBarItem;
