import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { memo, ReactElement } from 'react';
import { NAV_LOGO_IMAGE_URL } from '../../../../Constants';
import { NavSocialIcon } from '../Navbar';
import { NavItemType } from '../types';
import { useWeb3Connect } from '../../../../hooks/web3';
import NavBarItem from './NavBarItem';
import Web3Connector from '../Web3Connector';

interface MobileNavProps {
  navItems: NavItemType[];
  socialIcons: NavSocialIcon[];
}

const MobileNav = ({ navItems, socialIcons }: MobileNavProps): ReactElement => {
  const { connectedWalletAddress } = useWeb3Connect();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      w="80%"
      px={6}
      py={1}
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      border="2px solid #693722"
      borderRadius={35}
      boxShadow="#693722 0px 4px">
      <Flex w="100%" direction="column">
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Box>
            <Image h={45} src={NAV_LOGO_IMAGE_URL} />
          </Box>
          <IconButton
            aria-label="open menu"
            color="#693722"
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={6} h={6} /> : <HamburgerIcon w={8} h={8} />}
            variant="ghost"
          />
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <VStack
            justifyContent="space-between"
            direction="column"
            maxHeight="80vh"
            w="100%"
            color="#693722"
            fontWeight={600}
            align="center"
            textAlign="center"
            fontSize={{ base: 'md', md: 'lg' }}
            gap={5}
            mt={5}
            pb={6}>
            {navItems.map((navItem) => (
              <NavBarItem key={navItem.label} account={connectedWalletAddress} navItem={navItem} />
            ))}
            <Stack direction="row" spacing={3} pb={2}>
              {socialIcons.map((socialIcon) => (
                <LinkBox key={socialIcon.href}>
                  <LinkOverlay href={socialIcon.href} isExternal>
                    <IconButton
                      variant="outline"
                      colorScheme="white"
                      _hover={{ color: 'red.400', transitionDuration: '0.5s' }}
                      aria-label={socialIcon.ariaLabel}
                      icon={socialIcon.icon}
                    />
                  </LinkOverlay>
                </LinkBox>
              ))}
            </Stack>
            <Web3Connector />
          </VStack>
        </Collapse>
      </Flex>
    </Flex>
  );
};

export default memo(MobileNav);
