import { Image, Text, VStack, Progress } from "@chakra-ui/react";
import { ReactElement } from "react";

const LOGO_IMAGE_URL = "/logos/logo_with_bobo.png";

const Placeholder = (): ReactElement | null => {
  return (
    <VStack width={{ base: "75%", sm: "25%" }} textAlign="center" spacing={5}>
      <Image src={LOGO_IMAGE_URL} />
      <Text>Just a second! We're getting things ready for you...</Text>
      <Progress
        isIndeterminate
        colorScheme="orange"
        size="md"
        width="100%"
        top={3}
        borderRadius={5}
      />
    </VStack>
  );
};

export default Placeholder;
