import { Flex, ResponsiveValue } from '@chakra-ui/react';
import { memo, ReactElement } from 'react';
import { FaDiscord, FaMedium, FaTwitter } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { CSS } from '@react-spring/web';
import { NAV_LINKS } from './constants';
import { NavLinkKey } from './types';
import { useIsMobile } from '../../../hooks/viewport';
import DesktopNav from './Desktop/DesktopNav';
import MobileNav from './Mobile/MobileNav';
import OpenseaLogo from '../Icons/OpenseaLogo';

export interface NavSocialIcon {
  icon: ReactElement;
  href: string;
  ariaLabel: string;
}

export const NAV_SOCIAL_ICONS: NavSocialIcon[] = [
  {
    icon: <FaDiscord />,
    href: 'https://discord.gg/JQyw4D7bnG',
    ariaLabel: 'discord',
  },
  {
    icon: <FaTwitter />,
    href: 'https://twitter.com/BobaInu_NFT',
    ariaLabel: 'twitter',
  },
  {
    icon: <FaMedium />,
    href: 'https://medium.com/@bobainu',
    ariaLabel: 'medium',
  },
  {
    icon: <OpenseaLogo />,
    href: 'https://opensea.io/collection/boba-inu-1',
    ariaLabel: 'opensea',
  },
];

interface NavbarProps {
  position?: ResponsiveValue<CSS.Property.Position>;
  width?: string;
}

const Navbar = ({ position = 'fixed', width }: NavbarProps): ReactElement => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const formattedPathname =
    pathname === '/'
      ? pathname
      : pathname.charAt(pathname.length - 1) === '/'
      ? pathname.slice(0, pathname.length - 1)
      : pathname;

  const isValidPath = Object.keys(NAV_LINKS).includes(formattedPathname);
  const navLinks = isValidPath ? NAV_LINKS[formattedPathname as NavLinkKey] ?? [] : [];

  const NavComponent = isMobile ? MobileNav : DesktopNav;

  return (
    <Flex w="100%" justifyContent="center" position={position} top={5} zIndex={2}>
      <NavComponent width={width} navItems={navLinks} socialIcons={NAV_SOCIAL_ICONS} />
    </Flex>
  );
};

export default memo(Navbar);
