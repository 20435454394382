import { NavItemType, NavLinkKey } from './types';

export enum LINK_TYPE {
  SCROLL = 'scroll',
  LINK = 'link',
  MENU = 'menu',
  CUSTOM = 'custom',
}

const LINKS_WITH_HOME_MENU: NavItemType[] = [
  {
    label: 'Menu',
    type: LINK_TYPE.MENU,
    children: [
      {
        label: 'Home',
        type: LINK_TYPE.SCROLL,
        href: 'home',
      },
      {
        label: 'About',
        type: LINK_TYPE.SCROLL,
        href: 'project',
      },
      {
        label: 'Team',
        type: LINK_TYPE.SCROLL,
        href: 'team',
      },
      {
        label: 'Faq',
        type: LINK_TYPE.SCROLL,
        href: 'faq',
      },
    ],
  },
  {
    label: 'Roadmap',
    type: LINK_TYPE.LINK,
    href: '/roadmap',
  },
  {
    label: 'Apps',
    type: LINK_TYPE.LINK,
    href: '/holder/apps',
  },
];

const LINKS_WITH_HOME_LINK: NavItemType[] = [
  {
    label: 'Home',
    type: LINK_TYPE.LINK,
    href: '/',
  },
  {
    label: 'Roadmap',
    type: LINK_TYPE.LINK,
    href: '/roadmap',
  },
  {
    label: 'Apps',
    type: LINK_TYPE.LINK,
    href: '/holder/apps',
  },
];

export const NAV_LINKS: Partial<Record<NavLinkKey, NavItemType[]>> = {
  '/': LINKS_WITH_HOME_MENU,
  '/roadmap': LINKS_WITH_HOME_LINK,
  '/holder/apps': LINKS_WITH_HOME_LINK,
  '/holder/apps/build_a_bobo': LINKS_WITH_HOME_LINK,
};
