import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

export enum MessageToastType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

interface MessageToastProps {
  message: string | null | undefined;
  title: string;
  isClosable?: boolean;
  type?: typeof MessageToastType[keyof typeof MessageToastType];
}

interface MessageToastProps {
  message: string | null | undefined;
  title: string;
  isClosable?: boolean;
  type?: typeof MessageToastType[keyof typeof MessageToastType];
}

export const useMessageToast = ({
  message,
  title,
  isClosable = true,
  type = MessageToastType.WARNING,
}: MessageToastProps) => {
  const toast = useToast();

  useEffect(() => {
    if (message != null && message != '' && !toast.isActive(message)) {
      toast({
        id: message,
        title: title,
        description: message,
        status: type,
        duration: null,
        isClosable,
        position: 'bottom-right',
      });
    }
  }, [toast, message, title, isClosable, type]);
};
