import { ReactElement } from "react";
import { NIGHT_MARKET_IMAGE_URL } from "../../../../Constants";
import IllustratedDescription from "../../../Common/IllustratedDescription/IllustratedDescription";

const Goals = (): ReactElement => {
  const description =
    "We believe that everyone is to a large extent, a product of their upbringing. Our founders " +
    "come from a place where many of its inhabitants' fondest childhood memories stem from the savory aromas of street " +
    "delis and the matchless satisfaction of a Boba Tea on a hot summer day. Boba represents our memories with friends " +
    "and loved ones; the hardworking street vendor's method of providing for his family; the upset child's solace; " +
    "an emblem of once being a kid. We want to share this passion of ours with everyone around the world, in hopes " +
    "that we treat ourselves and everyone aroud us with love!";

  return (
    <IllustratedDescription
      description={description}
      header="Our Goals"
      imageSrc={NIGHT_MARKET_IMAGE_URL}
    />
  );
};

export default Goals;
