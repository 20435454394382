import { ReactElement } from 'react';
import { Box, Center, Image, keyframes, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { usePreloadImages } from '../../hooks/optimization';
import { useFadeInComponent } from '../../hooks/animation';
import {
  ROADMAP_BROWN_CARD_IMAGE_URL,
  ROADMAP_GREEN_CARD_IMAGE_URL,
  ROADMAP_IMAGE_URL,
  ROADMAP_ISLAND_1_IMAGE_URL,
  ROADMAP_ISLAND_2_IMAGE_URL,
  ROADMAP_ISLAND_3_IMAGE_URL,
  ROADMAP_ISLAND_4_IMAGE_URL,
  ROADMAP_PAGE_PRELOAD_IMAGE_URLS,
  ROADMAP_PURPLE_CARD_IMAGE_URL,
  ROADMAP_RED_CARD_IMAGE_URL,
} from '../../Constants';
import GlobalContainerWithNav from '../Common/Container/GlobalContainerWithNav';
import Header from '../Common/Header/Header';
import Spacer from '../Common/Spacer/Spacer';
import ContainerWithPlaceholder from '../Common/Container/ContainerWithPlaceholder';

const cardKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(3%);
  }
`;

const cardAnimation = `${cardKeyframes} 3s ease-in-out alternate infinite`;

const MobileRoadmapGraphic = (): ReactElement => {
  return (
    <VStack width="85%">
      <Box width="100%">
        <Image src={ROADMAP_BROWN_CARD_IMAGE_URL} animation={cardAnimation} />
        <Image src={ROADMAP_ISLAND_3_IMAGE_URL} position="relative" top="-10vh" zIndex={-1} />
      </Box>
      <Box width="100%">
        <Image src={ROADMAP_GREEN_CARD_IMAGE_URL} animation={cardAnimation} />
        <Image src={ROADMAP_ISLAND_1_IMAGE_URL} position="relative" top="-10vh" zIndex={-1} />
      </Box>
      <Box width="100%">
        <Image src={ROADMAP_PURPLE_CARD_IMAGE_URL} animation={cardAnimation} />
        <Image src={ROADMAP_ISLAND_4_IMAGE_URL} position="relative" top="-10vh" zIndex={-1} />
      </Box>
      <Box width="100%">
        <Image src={ROADMAP_RED_CARD_IMAGE_URL} animation={cardAnimation} />
        <Image src={ROADMAP_ISLAND_2_IMAGE_URL} position="relative" top="-8vh" zIndex={-1} />
      </Box>
    </VStack>
  );
};

const DesktopRoadmapGraphic = (): ReactElement => {
  return (
    <Center position="relative">
      <Image src={ROADMAP_IMAGE_URL} width="80%" />
      <Image
        src={ROADMAP_BROWN_CARD_IMAGE_URL}
        pos="absolute"
        width="25vw"
        top="-7vh"
        left="7vw"
        animation={cardAnimation}
      />
      <Image
        src={ROADMAP_GREEN_CARD_IMAGE_URL}
        pos="absolute"
        width="25vw"
        top="-6vh"
        right="19vw"
        animation={cardAnimation}
        style={{
          animationDelay: '-1s',
        }}
      />
      <Image
        src={ROADMAP_PURPLE_CARD_IMAGE_URL}
        pos="absolute"
        width="25vw"
        top="47vh"
        left="12vw"
        animation={cardAnimation}
        style={{
          animationDelay: '-2s',
        }}
      />
      <Image
        src={ROADMAP_RED_CARD_IMAGE_URL}
        pos="absolute"
        width="25vw"
        top="30vh"
        right="7vw"
        animation={cardAnimation}
        style={{
          animationDelay: '-3s',
        }}
      />
    </Center>
  );
};

const Roadmap = (): ReactElement | null => {
  const fadeInProps = useFadeInComponent();
  const loading = usePreloadImages(ROADMAP_PAGE_PRELOAD_IMAGE_URLS);
  const showMobileGraphic = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <ContainerWithPlaceholder loading={loading}>
      <GlobalContainerWithNav>
        <Spacer size={6} />
        <VStack spacing="10vh">
          <VStack textAlign="center" {...fadeInProps}>
            <Header>Roadmap</Header>
            <Spacer size={6} />
            <Text width={{ base: '85%', md: '65%' }}>
              We want Boba Inu to be a real part of your life. Each Boba Inu holder should feel connected as an
              important piece to a larger community. In order to do that, we have prepared a series of products, apps,
              and utilities to help you bring Bobo's vibe into your identity. This is by no means the final product, but
              a small fraction of what is to come.
            </Text>
          </VStack>
          {showMobileGraphic ? <MobileRoadmapGraphic /> : <DesktopRoadmapGraphic />}
        </VStack>
      </GlobalContainerWithNav>
    </ContainerWithPlaceholder>
  );
};

export default Roadmap;
