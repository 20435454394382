import { TeamMember } from './components/Home/Team/Team';
import HomePage from './components/Home/HomePage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Roadmap from './components/Roadmap/Roadmap';
import TokenGatedRoute from './components/Common/Gating/TokenGatedRoute';
import AppPage from './components/Apps/AppsPage';
import BuildABobo from './components/Apps/BuildABobo/BuildABobo';

export const BOBA_INU_SMART_CONTRACT_ADDRESS = '0x18D2B7E248561Edef9F491198FDC81d3e95dc17C';

export const ROUTES = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/roadmap',
    element: <Roadmap />,
  },
  {
    path: '/holder/apps',
    element: (
      <TokenGatedRoute>
        <AppPage />
      </TokenGatedRoute>
    ),
  },
  {
    path: '/holder/apps/build_a_bobo',
    element: (
      <TokenGatedRoute>
        <BuildABobo />
      </TokenGatedRoute>
    ),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
] as const;

export const PAGE_NOT_FOUND_IMAGE_URL = '/404/bobo_upside_down.png';
export const BOBO_AVATAR_IMAGE_URL = '/pfps/bobo_avatar.jpg';
export const BOBO_AVATAR_TRANSPARENT_IMAGE_URL = '/pfps/bobo_avatar_transparent.png';
export const NAV_LOGO_IMAGE_URL = '/logos/nav_bar_logo.png';
export const LOGO_WITH_BOBO_IMAGE_URL = '/logos/logo_with_bobo.png';
export const BUILD_A_BOBO_LOGO = '/logos/build_a_bobo_logo.png';
export const BOAT_IMAGE_URL = '/banner/boat.png';
export const CITY_IMAGE_URL = '/banner/city.png';
export const SPARKLES_IMAGE_URL = '/banner/sparkles.png';
export const WAVE_IMAGE_URL = '/banner/wave.png';
export const TITLE_IMAGE_URL = '/banner/title.png';
export const CLOUD_MULTITUDE_IMAGE_URL = '/accessories/cloud_multitude.png';
export const PINK_CLOUD_RIGHT_IMAGE_URL = '/accessories/pink_cloud_right.png';
export const WHITE_CLOUD_LEFT_IMAGE_URL = '/accessories/white_cloud_left.png';
export const WHITE_CLOUD_RIGHT_IMAGE_URL = '/accessories/white_cloud_right.png';
export const CURSOR_DEFAULT_IMAGE_URL = '/accessories/cursor_default.png';
export const CURSOR_POINTER_IMAGE_URL = '/accessories/cursor_pointer.png';
export const ORANGE_CLOUD_IMAGE_URL = '/accessories/orange_cloud.png';
export const ARROW_LEFT_IMAGE_URL = '/accessories/arrow_left.png';
export const ARROW_RIGHT_IMAGE_URL = '/accessories/arrow_right.png';
export const INUS_WALL_PHOTOS_IMAGE_URL = '/illustrations/inus_wall_photos.png';
export const BOBA_DRINK_IMAGE_URL = '/illustrations/boba_drink.png';
export const NIGHT_MARKET_IMAGE_URL = '/illustrations/night_market.png';
export const BOBO_FAMILY_IMAGE_URL = '/illustrations/bobo_family.png';
export const BOBO_BOBA_IMAGE_URL = '/illustrations/bobo_boba.png';
export const STAKING_IMAGE_URL = '/illustrations/staking.png';
export const WORKSHOP_IMAGE_URL = '/illustrations/workshop.png';
export const TEAM_JINA_IMAGE_URL = '/team/jina.jpg';
export const TEAM_JOHNNY_IMAGE_URL = '/team/johnny.jpg';
export const TEAM_BAWSA_IMAGE_URL = '/team/bawsa.jpg';
export const TEAM_GOATY_IMAGE_URL = '/team/goaty.jpg';
export const TEAM_SOFIA_IMAGE_URL = '/team/sofia.jpg';
export const TEAM_STORM_IMAGE_URL = '/team/storm.jpg';
export const TEAM_MADO_IMAGE_URL = '/team/mado.jpg';
export const ROADMAP_IMAGE_URL = '/roadmap/roadmap.png';
export const ROADMAP_BROWN_CARD_IMAGE_URL = '/roadmap/brown_card.png';
export const ROADMAP_RED_CARD_IMAGE_URL = '/roadmap/red_card.png';
export const ROADMAP_GREEN_CARD_IMAGE_URL = '/roadmap/green_card.png';
export const ROADMAP_PURPLE_CARD_IMAGE_URL = '/roadmap/purple_card.png';
export const ROADMAP_ISLAND_1_IMAGE_URL = '/roadmap/island_1.png';
export const ROADMAP_ISLAND_2_IMAGE_URL = '/roadmap/island_2.png';
export const ROADMAP_ISLAND_3_IMAGE_URL = '/roadmap/island_3.png';
export const ROADMAP_ISLAND_4_IMAGE_URL = '/roadmap/island_4.png';
export const JOIN_THE_CLUB_IMAGE_URL = '/illustrations/join_the_club.jpg';
export const BUILD_A_BOBO_WELCOME_GIF_URL = '/build_a_bobo/welcome.gif';

export const GLOBAL_PRELOAD_IMAGE_URLS = [
  PAGE_NOT_FOUND_IMAGE_URL,
  BOBO_AVATAR_IMAGE_URL,
  BOBO_AVATAR_TRANSPARENT_IMAGE_URL,
  NAV_LOGO_IMAGE_URL,
  BUILD_A_BOBO_LOGO,
  LOGO_WITH_BOBO_IMAGE_URL,
  CURSOR_DEFAULT_IMAGE_URL,
  CURSOR_POINTER_IMAGE_URL,
];

export const HOME_PAGE_PRELOAD_IMAGE_URLS = [
  BOAT_IMAGE_URL,
  CITY_IMAGE_URL,
  SPARKLES_IMAGE_URL,
  WAVE_IMAGE_URL,
  TITLE_IMAGE_URL,
  CLOUD_MULTITUDE_IMAGE_URL,
  PINK_CLOUD_RIGHT_IMAGE_URL,
  WHITE_CLOUD_LEFT_IMAGE_URL,
  WHITE_CLOUD_RIGHT_IMAGE_URL,
  ORANGE_CLOUD_IMAGE_URL,
  ARROW_LEFT_IMAGE_URL,
  ARROW_RIGHT_IMAGE_URL,
  INUS_WALL_PHOTOS_IMAGE_URL,
  BOBA_DRINK_IMAGE_URL,
  NIGHT_MARKET_IMAGE_URL,
  BOBO_FAMILY_IMAGE_URL,
  BOBO_BOBA_IMAGE_URL,
  STAKING_IMAGE_URL,
  WORKSHOP_IMAGE_URL,
  TEAM_JINA_IMAGE_URL,
  TEAM_JOHNNY_IMAGE_URL,
  TEAM_BAWSA_IMAGE_URL,
  TEAM_GOATY_IMAGE_URL,
  TEAM_SOFIA_IMAGE_URL,
  TEAM_STORM_IMAGE_URL,
  TEAM_MADO_IMAGE_URL,
];

export const ROADMAP_PAGE_PRELOAD_IMAGE_URLS = [
  ROADMAP_IMAGE_URL,
  ROADMAP_BROWN_CARD_IMAGE_URL,
  ROADMAP_RED_CARD_IMAGE_URL,
  ROADMAP_GREEN_CARD_IMAGE_URL,
  ROADMAP_PURPLE_CARD_IMAGE_URL,
  ROADMAP_ISLAND_1_IMAGE_URL,
  ROADMAP_ISLAND_2_IMAGE_URL,
  ROADMAP_ISLAND_3_IMAGE_URL,
  ROADMAP_ISLAND_4_IMAGE_URL,
];

export const TEAM_MEMBERS: TeamMember[] = [
  {
    name: 'Jina',
    role: 'Founder',
    imageSrc: TEAM_JINA_IMAGE_URL,
    link: 'https://twitter.com/jinastone0416',
  },
  {
    name: 'Johnny',
    role: 'Founder + Developer',
    imageSrc: TEAM_JOHNNY_IMAGE_URL,
    link: 'https://twitter.com/EconomyTw',
  },
  {
    name: 'Sofia',
    role: 'Artist',
    imageSrc: TEAM_SOFIA_IMAGE_URL,
    link: 'https://dribbble.com/SofiaIudina',
  },
  {
    name: 'Goaty',
    role: 'Collab Manager',
    imageSrc: TEAM_GOATY_IMAGE_URL,
    link: 'https://twitter.com/goatyy_eth',
  },
  {
    name: 'Mado',
    role: 'Community Manager',
    imageSrc: TEAM_MADO_IMAGE_URL,
    link: 'https://twitter.com/0xmadoo',
  },
  {
    name: 'Bawsa',
    role: 'Advisor',
    imageSrc: TEAM_BAWSA_IMAGE_URL,
    link: 'https://twitter.com/nftbawsa',
  },
  {
    name: 'Storm',
    role: 'Animator',
    imageSrc: TEAM_STORM_IMAGE_URL,
    link: 'https://www.upwork.com/freelancers/stormlabass',
  },
];

interface FaqItem {
  q: string;
  a: string;
}

export const FAQ_ITEMS: FaqItem[] = [
  {
    q: 'What is a Boba Inu?',
    a:
      'Boba Inu is a collection of randomly generated digital artwork consisting of the infamous Shiba ' +
      'Inu dog and Boba Tea. If you want to know more about the drink, wikipedia is probably the best ' +
      'place to start. The images you see were generated from over 200 different hand drawn traits and are ' +
      'stored on decentralized storage platforms to protect the authenticity and originality of each token. ' +
      'Each Boba Inu is perfectly unique, so you can rest assured that every Boba Inu in your possession is yours ' +
      'alone. No other person in the past, present, or future will possess the same item unless you choose to ' +
      'transfer ownership.',
  },
  {
    q: 'When is the offical launch?',
    a: '4/14/2023! Starting at 8 am PST!',
  },
  {
    q: 'How do I purchase a Boba Inu?',
    a:
      "You'll need an Ethereum wallet. Metamask and Coinbase Wallet are great choices. " +
      'Minting will be made available on a TBA date. You will be able to purchase ' +
      'multiple NFTs in a single transaction to avoid higher gas fees. Once all the NFTs sell out, you will need to ' +
      'purchase them from other holders on Opensea.',
  },
  {
    q: 'I want to learn more about Boba Inu. Where should I go?',
    a:
      "Follow us on social media! Our Discord is now public, and we'll be focusing on it heavily for project updates, " +
      'announcements, giveaways, and so much more. You should most definitely follow us on Twitter too. The links are ' +
      'attached at the top and bottom of this website.',
  },
];

export const Colors = {
  Button: {
    PRIMARY: '#a285d7',
    SECONDARY: '#7e726a',
  },
  Background: {
    PRIMARY: '#fde9e1',
    SECONDARY: '#c6f4ff',
  },
  Text: {
    HEADER_PRIMARY: '#814e3b',
    HEADER_SECONDARY: '#b86f50',
    NORMAL: '#442316',
    NORMAL_SECONDARY: '#524a44',
  },
} as const;

export const Fonts = {
  Header: {
    PRIMARY: 'hansip',
    SECONDARY: 'hansip',
  },
  Normal: "'Poppins', serif",
} as const;

export const EMPTY_STRING = '';
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:5000';
export const BACKEND_URL_PREFIX = 'api/v1';
