import { Flex } from '@chakra-ui/react';
import CityScape from './Images/CityScape';
import BoBoat from './Images/BoBoat';
import WelcomeText from './WelcomeText';
import { ReactElement } from 'react';

const Banner = (): ReactElement => {
  return (
    <Flex
      id="home"
      justifyContent="center"
      minH={{ base: '115vh', sm: '105vh', md: '100vh', lg: '90vh' }}
      overflowX="clip"
      overflowY="initial"
      position="relative"
      style={{ marginTop: 0 }}
      w="100%">
      <CityScape />
      <BoBoat />
      <WelcomeText />
    </Flex>
  );
};

export default Banner;
