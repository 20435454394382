import { watchAccount } from '@wagmi/core';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ReactElement } from 'react';
import { GLOBAL_PRELOAD_IMAGE_URLS, ROUTES } from './Constants';
import { usePreloadImages } from './hooks/optimization';
import { useWeb3Connect } from './hooks/web3';
import { useMessageToast } from './hooks/toast';
import WalletconnectionModal from './components/Common/Web3/WalletConnectionModal';
import './App.css';

const router = createBrowserRouter(ROUTES.map((route) => route));

const App = (): ReactElement | null => {
  const loading = usePreloadImages(GLOBAL_PRELOAD_IMAGE_URLS);
  const { isConnected, isConnectedToMainnet, connectedChain, connectedWalletAddress } = useWeb3Connect();
  watchAccount((account) => {
    const address = account.address ?? null;
    if (address !== connectedWalletAddress) {
      window.location.reload();
    }
  });

  const isConnectedToTestnet = isConnected && !isConnectedToMainnet;

  useMessageToast({
    message: isConnectedToTestnet
      ? `You're on the ${connectedChain?.name} network. Please switch back to the Ethereum mainnet`
      : null,
    title: 'Wrong Network',
  });

  return !loading ? (
    <>
      <RouterProvider router={router} />
      <WalletconnectionModal />
    </>
  ) : null;
};

export default App;
