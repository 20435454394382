import { Flex, HStack, Image, keyframes, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Colors, ORANGE_CLOUD_IMAGE_URL, TITLE_IMAGE_URL, WHITE_CLOUD_LEFT_IMAGE_URL } from '../../../Constants';
import { useFadeInComponent } from '../../../hooks/animation';
import Header from '../../Common/Header/Header';

const WelcomeText = (): ReactElement => {
  const fadeInProps = useFadeInComponent();

  const textPosition = useBreakpointValue({
    base: { justifyContent: 'center' },
    lg: { marginLeft: '9vw', marginBottom: '5vh' },
    xl: { marginLeft: '10vw', marginBottom: '13vh' },
  });

  const whiteCloudAnimationKeyframes = keyframes`
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(8%);
    }
  `;

  const whiteCloudAnimation = `${whiteCloudAnimationKeyframes} 4s ease-in-out alternate infinite`;

  const orangeCloudAnimationKeyframes = keyframes`
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-16%);
    }
  `;

  const orangeCloudAnimation = `${orangeCloudAnimationKeyframes} 4s ease-in-out alternate infinite`;

  return (
    <Flex alignSelf="end" width="100%" {...textPosition} {...fadeInProps}>
      <Stack
        position="relative"
        spacing={10}
        textAlign={{ base: 'center', lg: 'left' }}
        maxWidth={{ base: '85vw', lg: '43vw' }}>
        <Image
          animation={orangeCloudAnimation}
          left={{ base: '-10vw', sm: '-5vw', lg: '-5vw' }}
          position="absolute"
          src={ORANGE_CLOUD_IMAGE_URL}
          top={{ base: '-9vh', sm: '-3vh', lg: '-5vh', xl: '-3vh' }}
          zIndex={-1}
        />
        <Image
          animation={whiteCloudAnimation}
          position="absolute"
          right={{ base: '-5vw', sm: '55vw', lg: '-5vw' }}
          src={WHITE_CLOUD_LEFT_IMAGE_URL}
          top={{ base: '-5vh', sm: '-25vh', lg: '-10vh', xl: '-8vh' }}
          zIndex={-1}
        />
        <HStack alignItems={{ base: 'center', md: 'end' }} justifyContent={{ base: 'center', lg: 'left' }} spacing={3}>
          <Header
            color={Colors.Text.HEADER_PRIMARY}
            fontSize={{ base: '2xl', lg: '3xl', xl: '4xl' }}
            lineHeight="inherit">
            Welcome to
          </Header>
          <Image display="inline" maxW={{ base: '40%', lg: '55%' }} src={TITLE_IMAGE_URL} />
        </HStack>
        <Text>
          Boba Inu is a brand and community built upon the story of Bobo: the absolute champion of fluff and attitude.
          Follow Bobo as he embarks on his nose led adventures, accompanied by his love of great food and sceneries, to
          the ends of the world. We invite those who seek freedom in a face-paced society to join us on our journey,
          escape extremisms, and set sail to better endings.
          <br />
          <br />
          In this vast overwhelming world of unpleasantry and uncertainty, don't forget to pause and look at the beauty
          around. And always - always - drink some Boba.
        </Text>
      </Stack>
    </Flex>
  );
};

export default WelcomeText;
