import { ReactElement } from 'react';
import { Card as ChakraCard, CardProps } from '@chakra-ui/react';

const Card = ({ children, ...props }: CardProps): ReactElement => {
  return (
    <ChakraCard borderRadius={10} bgColor="#fffaf8" {...props}>
      {children}
    </ChakraCard>
  );
};

export default Card;
