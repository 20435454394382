import { Box, Flex, IconButton, Image, Stack, LinkOverlay, LinkBox } from '@chakra-ui/react';
import { memo, ReactElement } from 'react';
import { NAV_LOGO_IMAGE_URL } from '../../../../Constants';
import { useWeb3Connect } from '../../../../hooks/web3';
import { NavSocialIcon } from '../Navbar';
import { NavItemType } from '../types';
import NavBarItem from './NavBarItem';
import Web3Connector from '../Web3Connector';

interface DesktopNavProps {
  width?: string;
  navItems: NavItemType[];
  socialIcons: NavSocialIcon[];
}

const DesktopNav = ({ width = '80%', navItems, socialIcons }: DesktopNavProps): ReactElement | null => {
  const { connectedWalletAddress } = useWeb3Connect();

  return (
    <Flex
      w={width}
      px={6}
      py={1}
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      border="2px solid #693722"
      borderRadius={35}
      boxShadow="#693722 0px 4px">
      <Flex flex={1} justify="start">
        <Box>
          <Image h={45} src={NAV_LOGO_IMAGE_URL} />
        </Box>
        <Flex ml={10} alignItems="center">
          <Stack direction={'row'}>
            {navItems.map((navItem) => (
              <NavBarItem key={navItem.label} account={connectedWalletAddress} navItem={navItem} />
            ))}
          </Stack>
        </Flex>
      </Flex>
      <Stack align="center" justify="flex-end" direction="row" spacing={4}>
        <Stack align="center" direction="row">
          {socialIcons.map((socialIcon) => (
            <LinkBox key={socialIcon.href}>
              <LinkOverlay href={socialIcon.href} isExternal>
                <IconButton
                  cursor="var(--cursor-pointer)"
                  size="md"
                  variant="ghost"
                  color="#693722"
                  _hover={{
                    bgColor: '#693722',
                    color: 'white',
                    transitionDuration: '0.25s',
                  }}
                  _active={{ bgColor: '#693722', color: 'white' }}
                  aria-label={socialIcon.ariaLabel}
                  icon={socialIcon.icon}
                />
              </LinkOverlay>
            </LinkBox>
          ))}
        </Stack>
        <Web3Connector />
      </Stack>
    </Flex>
  );
};

export default memo(DesktopNav);
